import React from 'react';

export default function TsukishimaPage() {
    return (
            <section className="section-left">
                <div className="left-content">
                <h1 className="left-content-title">Tsukishima</h1>
                <h2 className="left-content-subtitle">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac tortor consequat
                    lorem efficitur vehicula. Quisque quis ante tempor, gravida diam eu, cursus enim.
                    Vestibulum eget ipsum semper, consequat urna sit amet, vestibulum urna. Praesent
                    bibendum viverra augue in condimentum. Donec orci sem, lobortis id dolor sed,
                    venenatis iaculis justo. Aliquam erat volutpat. Cras eget tortor risus. Ut gravida
                    consequat porttitor. Integer sagittis, urna ac tristique varius, turpis turpis
                    convallis ligula, et efficitur quam augue non diam. Morbi faucibus sed sem non
                    finibus.Nunc at vulputate massa. Suspendisse .
                </h2>
                </div>
            </section>
    );
}
